// src/App.js
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './App.css';

const pages = {
  GRADSKA: "Градска",
  POLIGON: "Полигон",
  TESTOVI: "Тестови",
};

function App() {
  const [page, setPage] = useState(pages.GRADSKA);
  const [tableData, setTableData] = useState({ weekly: [], school: [] });

  useEffect(() => {
    // Load data for the current page
    const loadPageData = (weeklyFile, schoolFile) => {
      // Load weekly stats
      Papa.parse(weeklyFile, {
        download: true,
        header: true,
        encoding: 'UTF-8',
        complete: (results) => setTableData((prev) => ({ ...prev, weekly: results.data })),
      });

      // Load school stats
      Papa.parse(schoolFile, {
        download: true,
        header: true,
        encoding: 'UTF-8',
        complete: (results) => setTableData((prev) => ({ ...prev, school: results.data })),
      });
    };

    if (page === pages.GRADSKA) {
      loadPageData('/practical_weekly_stats.csv', '/practical_school_stats.csv');
    } else if (page === pages.POLIGON) {
      loadPageData('/polygon_weekly_stats.csv', '/polygon_school_stats.csv');
    } else if (page === pages.TESTOVI) {
      loadPageData('/theory_weekly_stats.csv', '/theory_school_stats.csv');
    }
  }, [page]);

  return (
    <div className="app">
      <header>
        <h1>Статистика за положен возачки испит</h1>
        <nav>
          {Object.values(pages).map((p) => (
            <button
              key={p}
              className={page === p ? 'active' : ''}
              onClick={() => setPage(p)}
            >
              {p}
            </button>
          ))}
        </nav>
      </header>

      <div className="table-container">
        <h2>Неделна статистика</h2>
        <SortableTable data={tableData.weekly} />

        <h2>Статистика за авто школи</h2>
        <SortableTable data={tableData.school} />
      </div>
    </div>
  );
}

function SortableTable({ data }) {
  const [sortConfig, setSortConfig] = useState(null);

  const sortedData = React.useMemo(() => {
    if (!sortConfig) return data;

    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      if (sortConfig.direction === 'ascending') {
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      }
      return bValue.localeCompare(aValue, undefined, { numeric: true });
    });

    return sorted;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prev) => {
      if (prev && prev.key === key && prev.direction === 'ascending') {
        return { key, direction: 'descending' };
      }
      return { key, direction: 'ascending' };
    });
  };

  if (data.length === 0) {
    return <p>Loading...</p>;
  }

  const headers = Object.keys(data[0]).filter((key) => key);

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} onClick={() => requestSort(header)}>
              {header} {sortConfig?.key === header ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header, cellIndex) => (
              <td key={cellIndex}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default App;
